// React and Third-Party Libraries
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Joyride from "react-joyride";
// Material-UI Components
import { 
    Typography, 
    Sheet, 
    Box, 
    CardContent, 
    Card, 
    CardActions, 
    Button, 
    Divider, 
    Select, 
    Option, 
    Input, 
    Textarea, 
    Chip, 
    FormLabel, 
    LinearProgress, 
    IconButton 
} from "@mui/joy";

// Material-UI Icons
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import AttributionIcon from '@mui/icons-material/Attribution';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

// Custom Components
import { NoteTypeSelect } from "../components/custom/NoteTypeSelect";
import MicrophoneSelect from "../components/custom/audio/MicSelect";
import TimerComponent from "../components/custom/Timer";
import AudioDisplay from "../components/custom/audio/AudioDisplay";
import UploadTranscriptPopup from "../components/modals/UploadAudio";
import NameAppointmentPopup from "../components/modals/NameAppointmentPopup";
import NavBlocker from "../components/modals/NavBlocker";
import { useNewVisitTour } from "../tour/useNewVisitTour";
import Joyrideconfig from "../tour/Joyrideconfig";

// Context
import { AlertContext } from "../context/AlertFlag";

// Hooks
import { useFetchUserPreferences } from "../hooks/api/UserPreferences";
import { useFetchUser } from "../hooks/api/User";

// Utilities
import { 
    checkVisitAllowed 
} from "../utils/NewVisitUtils";
import { 
    clean_microphone, 
    get_microphone_permissions, 
    grab_wakelock, 
    setup_media_recorder 
} from "../utils/AudioUtils";
import { 
    getPresignedURL, 
    partitionBySize, 
    putAudioIntoPresigned, 
    putIntoMultipartPresigned
} from "../utils/AWSHelpers";

// Services
import { get_visit, post_visit } from "../services/VisitRouter";


// Styling
import '../styling/NewVisitTour.css';


export default function NewVisitPage() {
// Context and Navigation
const { addAlert } = useContext(AlertContext);
const navigate = useNavigate();

// User and Preferences
const { preferences, loadingPreferences, errorPreferences } = useFetchUserPreferences();
const { user, loadingUser } = useFetchUser();

// State Management
const [recording, setRecording] = useState(false);
const [audioReady, setAudioReady] = useState(false);
const [currentTime, setCurrentTime] = useState(0);
const [title, setTitle] = useState("");
const [debouncedTitle, setDebouncedTitle] = useState("");
const [noteType, setNoteType] = useState();
const [generating, setGenerating] = useState(false);
const [progress, setProgress] = useState(0);
const [progressStatus, setProgressStatus] = useState("");
const [uploadAudioModal, setUploadAudioModal] = useState(false);
const [nameVisitModal, setNameVisitModal] = useState(false);

// Refs
const audioChunks = useRef([]);
const audioCodecs = useRef(null);
const mediaRecorder = useRef(null);
const wakeLock = useRef(null);
const fileRef = useRef(null);
const intervalRef = useRef(null);
const audio = useRef(null);

//Tour (useNewVisitTour.js)
const {
    runTour,
    setRunTour,
    stepIndex,
    setStepIndex,
    steps,
    handleJoyrideCallback
  } = useNewVisitTour({
    user,
    loadingUser,
    currentTime,
    title,
    debouncedTitle,
    setDebouncedTitle,
  });


    const toggleMicrophone = async () => {
        try {
            if (!audio.current) {
                addAlert("Microphone permissions not granted. Please let OneChart have access to your microphone.", "danger");
                return;
            }

            // Play
            if (!recording && !mediaRecorder.current) {
                await checkVisitAllowed(currentTime, addAlert);

                const media = await setup_media_recorder(audio.current, (chunk) => { audioChunks.current.push(chunk) })
                mediaRecorder.current = media['media_recorder'];
                audioCodecs.current = media['audio_codecs'];
                wakeLock.current = await grab_wakelock();

                mediaRecorder.current.start(10000);
                setRecording(true);
                if (runTour && steps[stepIndex]?.target === ".tour-start-capturing") {
                    setStepIndex(stepIndex + 1);
                }
            } 
            // UnPause
            else if (!recording) {
                if (currentTime >= 9000) {
                    addAlert("Limit of 2 hours reached", "danger");
                    return;
                }
                mediaRecorder.current.resume();
                setRecording(true);
            } 
            // Pause
            else {
                mediaRecorder.current.pause();
                setRecording(false);
            }
        } catch(error) {
            console.log(error);
        }
    }

    // Can only update stream on pause or before starting
    // Pause and disable current media
    async function updateRecordingStream(new_media_stream) {
        if (audio.current) {
            audio.current.getTracks().forEach((track) => track.stop());
        }
        audio.current = new_media_stream;

        let check_paused;
        if (mediaRecorder.current) {
            if (!recording) {
                check_paused = true;
            } else {
                check_paused = false;
            }
            mediaRecorder.current.stop();
            mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
            mediaRecorder.current = null;
        }

        if (check_paused) {
            const media = await setup_media_recorder(new_media_stream, (chunk) => {
                audioChunks.current.push(chunk);
            });
    
            mediaRecorder.current = media["media_recorder"];
            audioCodecs.current = media["audio_codecs"];

            mediaRecorder.current.start(10000);
            mediaRecorder.current.pause();
            console.log("MediaRecorder restarted with the new stream");
        }
    }    

    function check_note_allowed() {
        if (currentTime < 20) {
            addAlert("Please record at least 20 seconds!", "danger");
            throw new Error("Minimum of 20 seconds not reached");
        } else if (generating) {
            addAlert("Note currently generating!", "danger");
            throw new Error("Note currently generating!");
        }
        return;
    }

    async function generate_note(name = null) {
        try {

            setGenerating(true);
            setRecording(false);
            // setProgressStatus("Starting capture...")

            let audioBlob;
            let bar_progress = 0;
            if (!fileRef.current) {
                check_note_allowed();
                mediaRecorder.current.stop();
                mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
                audio.current.getTracks().forEach((track) => track.stop());
                wakeLock.current.release();
                wakeLock.current = null;
    
                const awaitValidAudioBlob = new Promise((resolve) => {
                    const checkAudioAvailable = setInterval(() => {
                        if (audioChunks.current.length > 0) {
                            console.log("ondataavailable fired");
                            clearInterval(checkAudioAvailable)
                            resolve();
                        }
                    }, 300)
                })
                await awaitValidAudioBlob;
    
                audioBlob = audioCodecs.current ? new Blob(audioChunks.current, { type: audioCodecs.current }) : new Blob(audioChunks.current);
                console.log(`Blobbed with ${audioCodecs.current}`)
            } else {
                audioBlob = fileRef.current;
            }
            let presigned_fields;
            if (audioBlob.size > (20 * 1024 * 1024)) {
                const object_name = await putIntoMultipartPresigned(audioBlob);
                presigned_fields = {
                    "fields": {
                        "key": object_name
                    }
                }
            } else {
                presigned_fields = await getPresignedURL();
                await putAudioIntoPresigned(presigned_fields, audioBlob);
            }
            bar_progress = 15;
            setProgress(bar_progress);
            console.log(presigned_fields)
            const visit = await post_visit(presigned_fields, title, noteType, preferences);
            bar_progress = 20;
            setProgress(bar_progress);
            addAlert("Visit created!", "success");
            addAlert("Note queued!", "success");
            fileRef.current = null;
            audioChunks.current = [];

            // Repeatedly request status of file
            intervalRef.current = setInterval( async () => {
                try {
                    const response = await get_visit(visit.id)
                    if (response['generate_note'] !== null && response['transcription'] !== null) {
                        // Navigate
                        clearInterval(intervalRef.current);
                        intervalRef.current = null;
                        setGenerating(false);
                        navigate(`/past-visits/${visit.id}`);
                    } else {
                        bar_progress < 95 ? bar_progress += 5 : bar_progress = bar_progress;
                        setProgress(bar_progress);
                    }
                }
                catch(error) {
                    console.log(error);
                    addAlert("Something unexpected happen. Please try again", "danger");
                    setGenerating(false);
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            }, 4000)

        } catch (error) {
            addAlert("Cannot generate note.", "danger");
            setGenerating(false);
            throw new Error(error);
        }
    }

    // Setup mic
    useEffect(() => {
        get_microphone_permissions((audio_stream) => { 
            audio.current = audio_stream;
            setAudioReady(true);
        }, addAlert);
        return () => {
            clean_microphone(audio.current, mediaRecorder.current);
            audio.current = null;
            mediaRecorder.current = null;
            if (wakeLock.current) {
                wakeLock.current.release();
                wakeLock.current = null;
            }
        }
    }, [])


    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
    }, [])

    useEffect(() => {
        if (preferences) {
            setNoteType(preferences['note_type']);
        }
    }, [preferences])




    return <Sheet sx={{height: "100%", width: "100%", background: "white", overflow: "auto"}}>
            <Joyrideconfig
            steps={steps}
            runTour={runTour}
            stepIndex={stepIndex}
            handleJoyrideCallback={handleJoyrideCallback}
            />

            <Box
                sx={{
                    position: "fixed",
                    bottom: 16, 
                    right: 16, 
                    zIndex: 1100, 
                }}
                >
                <Button
                    onClick={() => setRunTour(true)}
                    size="sm"
                    variant="soft"
                    sx={{
                    borderRadius: "50%", 
                    minWidth: 40, 
                    height: 40,
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center", 
                    backgroundColor: "var(--dark-blue-button)",
                    color: "white",
                    fontSize: "1.25rem", 
                    fontWeight: "bold", 
                    "&:hover": {
                        backgroundColor: "var(--dark-blue-button-hover)",
                    },
                    }}
                >
                    ?
                </Button>
            </Box>


        <UploadTranscriptPopup open={uploadAudioModal} closePopup={() => {setUploadAudioModal(false)}} audioUploaded={(file) => {
            fileRef.current = file;
            if (title.length <= 0) {
                setNameVisitModal(true);
            } else {
                generate_note();
            }
        }}/>
        <NameAppointmentPopup open={nameVisitModal} closePopup={() => {setNameVisitModal(false)}} handleName={(name) => {
            setTitle(name);
            generate_note(name);
            setNameVisitModal(false);
        }}/>
        <NavBlocker dirty={recording || (generating && progress < 20 ) || audioChunks.current.length > 0 }/>
        <Box sx={{display: "flex", px: 4, paddingTop: 3.75, paddingBottom: 2, alignItems: "center", gap: 2, height: { sx: "5%", md: "10%"}}}>
            <Typography level="h3"> New Visit </Typography>
            <Chip startDecorator={ <CircleIcon /> } size="sm" color={recording ? "neutral" : generating ? "warning" : audioReady ? "success" : "danger"} sx={{ height: "fit-content", p: 1 }}> 
                {recording ? "Recording..." : generating ? "Capturing..." : audioReady ? "Ready" : "Not Ready"}
            </Chip>
        </Box>
        <Divider />
        <Box sx={{p: 2, backgroundColor: "var(--joy-palette-neutral-50)", height: "80%"}}>
            <Box sx={{display: "flex", p: 2}}>
                <Card sx={{background: "white", width: "100%"}}>
                    <CardContent>
                        <Box sx={{width: "100%" }} className="tour-patient-name">
                            <Typography level="body-sm"> Patient Name </Typography>
                            <Input value={title} onChange={(event) => {setTitle(event.target.value)}} label="Patient Name" placeholder="Full Name" sx={{height: "42px"}} startDecorator={<PersonIcon />}/>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: {xs: 'column', lg: 'row'}, justifyContent: "space-between", py: 2}}>
                            <Box sx={{ width: { xs: "100%", lg: "100%" } }} className="tour-visit-type">
                                <Typography level="body-sm">Visit Type</Typography>
                                {preferences && (
                                    <NoteTypeSelect
                                    onChange={(_, noteSelected) => {
                                        setNoteType(noteSelected);
                                        // Move to the next tour step if the current step is ".tour-visit-type"
                                        if (runTour && steps[stepIndex]?.target === ".tour-visit-type") {
                                            setStepIndex(stepIndex + 1);
                                        }
                                    }}
                                    value={noteType}
                                    disabled={loadingPreferences || errorPreferences}
                                    calmTitle={false}
                                    sx={{ width: "100%", alignSelf: "center" }}
                                    noteViews={preferences["note_views"]}
                                    />
                                )}
                            </Box>
                            {/* <Box sx={{ width: { xs: "100%", lg: "45%" } }} className="tour-pronouns">
                                <Typography level="body-sm">Pronouns</Typography>
                                <Select
                                    placeholder="Patient pronouns"
                                    startDecorator={<AttributionIcon />}
                                    onChange={(event) => {
                                    const selectedPronoun = event.target.value;

                                    // Automatically proceed to the next tour step if the current step is ".tour-pronouns"
                                    if (runTour && steps[stepIndex]?.target === ".tour-pronouns") {
                                        setStepIndex(stepIndex + 1);
                                    }
                                    }}
                                >
                                    <Option value="he/him">he/him</Option>
                                    <Option value="she/her">she/her</Option>
                                </Select>
                            </Box> */}

                            {/* <Box sx={{width: {xs: "100%", lg: "20%"}}}>
                                <Typography level="body-sm"> Language </Typography>
                                <Select placeholder="Language" startDecorator={<TranslateIcon />}>
                                    <Option value="en-US"> english </Option>
                                </Select>
                            </Box> */}
                        </Box>
                    </CardContent> 
                    <Divider sx={{mx: 2}}/>
                    <CardActions sx={{ alignItems: "end", width: "100%", flexDirection: { xs: "column", sm: "row" }, display: "flex"}}>
                        <MicrophoneSelect updateRecordingStream={updateRecordingStream} disabled={recording || generating}/>
                        {!recording && !mediaRecorder.current && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PlayCircleOutlineIcon />} sx={{display: "flex"}} disabled={generating} className="tour-start-capturing"> Start Capturing </Button>}
                        {!recording && !mediaRecorder.current && <Button onClick={() => {
                                setUploadAudioModal(true);
                            }} startDecorator={<CloudUploadOutlinedIcon />} sx={{display: "flex", backgroundColor: "white", width: {xs: "100%", sm: "25%"}}} disabled={generating} variant="plain"> Upload Audio </Button>}
                        {!recording && mediaRecorder.current && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PlayArrowIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Unpause </Button>}
                        {recording && mediaRecorder.current && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PauseIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Pause</Button>}
                        {mediaRecorder.current && <Button onClick={() => {
                            if (title.length <= 0) {
                                setNameVisitModal(true);
                            } else {
                                generate_note();
                                setStepIndex((prevStepIndex) => prevStepIndex + 1);
                            }
                        }} startDecorator={<NoteAddIcon />} sx={{display: "flex", backgroundColor: "var(--dark-blue-button)", '&:hover': {backgroundColor: 'var(--dark-blue-button-hover)'}}} disabled={currentTime < 20 || generating}  className="tour-complete"> Complete</Button>}
                    </CardActions>
                    <Divider sx={{m: 2}}/>
                    <Box sx={{ display: "flex", alignItems:"center", width: "100%" }}>
                        {recording && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "red", borderRadius: "50%", alignSelf: "center", animation: "blink 1s infinite"}}/>}
                        {!recording && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "grey", borderRadius: "50%", alignSelf: "center" }}/>}
                        <TimerComponent start={recording} setCurrentTime={setCurrentTime}/>
                        {recording && !generating && audioReady && <AudioDisplay audio={audio.current}/>}
                        {!recording && !generating && <AudioDisplay/>}
                        {generating && <LinearProgress determinate value={progress} sx={{ mx: 2 }}> {progressStatus} </LinearProgress>}
                    </Box>
                    <Typography level="body-xs" sx={{ alignSelf: "center"}}> Make sure to obtain patient consent if you're using OneChart in an appointment. </Typography>
                </Card>
            </Box>

            {/* <Typography level="body-sm" sx={{mx: 2}}> Shorthand </Typography>
            <Textarea value={contextText} onChange={(event) => setContextText(event.target.value)} sx={{ height: "25%", mx: 2, px: 2 }} placeholder="To help with capturing context, enter in any additional notes..." endDecorator={<Typography level="body-xs" sx={{ ml: 'auto' }}> {contextText.length} character(s) </Typography>}>
                {contextText}
            </Textarea> */}
        </Box>
    </Sheet>
}