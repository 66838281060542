import React, { useState, useEffect, useContext, useRef } from "react";
import PastVisitCard from "../components/custom/PastVisitCard";
import { AlertContext } from "../context/AlertFlag";
import {
  Box,
  CircularProgress,
  List,
  listItemButtonClasses,
  Sheet,
  Typography,
  Input,
  IconButton,
} from "@mui/joy";
import Pagination from "../components/custom/Pagination";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useFetchVisits } from "../hooks/api/Visits";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function PastVisits() {
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();

  
  const [searchString, setSearchString] = useState(
    new URLSearchParams(location.search).get("title") || ""
  );

  const [timeRange, setTimeRange] = useState("1 year");
  const [page, setPage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);


  const {
    visits,
    totalVisits,
    visitsLoading,
    visitsError,
    reload,
  } = useFetchVisits(timeRange, page, 25, searchString);

  const dateHeader = useRef(null);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchString(value);

    
    const queryParams = new URLSearchParams(location.search);
    if (value) {
      queryParams.set("title", value);
    } else {
      queryParams.delete("title");
    }
    navigate({ search: queryParams.toString() });
  };

  useEffect(() => {
    if (visitsError) {
      addAlert(visitsError.message, "danger");
    }
  }, [visitsError, addAlert]);

  return (
    <Sheet sx={{ overflow: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        {/* LEFT-SIDE (Search + List) */}
        <Box
          sx={{
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            borderRight: "1px solid var(--joy-palette-divider)",
            width: collapsed ? "0px" : "25%",
            transition: "width 0.3s ease",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            visibility: collapsed ? "hidden" : "visible",
            opacity: collapsed ? 0 : 1,
            transitionProperty: "width, visibility, opacity",
          }}
        >
          {/* SEARCH INPUT */}
          <Box sx={{ py: 2, px: 1, display: "flex", gap: 1 }}>
            <Input
              sx={{ width: "100%" }}
              value={searchString}
              onChange={handleSearch}
              startDecorator={<SearchIcon />}
              placeholder="Search by Title"
            />
            <IconButton sx={{ width: "25px" }} onClick={() => setCollapsed(true)}>
              <MenuOpenIcon />
            </IconButton>
          </Box>

          {/* VISITS LIST */}
          <List
            sx={{
                [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
                borderLeft: "2px solid",
                borderLeftColor: "var(--joy-palette-primary-outlinedBorder)",
                },
                padding: "0px",
                width: "100%",
                position: "relative", 
            }}
            >
            {visitsLoading && visits.length === 0 ? (
                <Box sx={{ p: 1 }}>
                  <CircularProgress size="sm" />
                </Box>
            ) : (
                visits &&
                visits.map((item) => {
                const nowDate = new Date();
                const yesterday = new Date();
                yesterday.setDate(nowDate.getDate() - 1);
                const date = new Date(`${item.created_at}Z`);

                let toAddHeader = date.toLocaleString("en-US", dateOptions);

                if (nowDate.toDateString() === date.toDateString()) {
                    toAddHeader = `Today - ${nowDate.toLocaleDateString(
                    "en-US",
                    dateOptions
                    )}`;
                } else if (yesterday.toDateString() === date.toDateString()) {
                    toAddHeader = `Yesterday - ${yesterday.toLocaleDateString(
                    "en-US",
                    dateOptions
                    )}`;
                }

                if (dateHeader.current !== toAddHeader) {
                    dateHeader.current = toAddHeader;
                    return (
                    <React.Fragment key={item.id}>
                        <Box
                        sx={{
                            display: "flex",
                            p: 1,
                            backgroundColor: "var(--main-blue-background)",
                            border: "1px solid var(--main-blue)",
                        }}
                        >
                        <Typography level="body-sm">{toAddHeader}</Typography>
                        </Box>
                        <PastVisitCard note={item} />
                    </React.Fragment>
                    );
                } else {
                    return <PastVisitCard note={item} key={item.id} />;
                }
                })
            )}
            </List>


          {/* PAGINATION */}
          {visits && visits.length > 0 && (
            <Pagination
              count={Math.ceil(totalVisits / 25)}
              page={page}
              onChange={handlePageChange}
            />
          )}
        </Box>

        {/* COLLAPSED BUTTON */}
        {collapsed && (
          <IconButton
            sx={{ width: { sm: "4%", md: "2%" } }}
            onClick={() => setCollapsed(false)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        )}

        {/* RIGHT-SIDE (OUTLET) */}
        <Sheet
          sx={{
            width: collapsed ? { sm: "96%", md: "98%" } : "75%",
            height: "100vh",
          }}
        >
          <Outlet context={{ reload }} />
        </Sheet>
      </Box>
    </Sheet>
  );
}

export default PastVisits;
