import React, { useContext, useEffect, useState } from "react";
import "../styling/SignUp.css";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  Select,
  Typography,
  Option,
} from "@mui/joy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AlertContext } from "../context/AlertFlag";
import FloatingLabelInput from "../components/joy/FloatingInput";
import Stepper from "@mui/joy/Stepper";
import StepIndicator from "@mui/joy/StepIndicator";
import { formatPhoneNumber } from "../utils/FormatNoteNameAndText";
import Step, { stepClasses } from "@mui/joy/Step";
import Autocomplete from '@mui/joy/Autocomplete';
import TextField from '@mui/joy/TextField';
import { HyperLink } from "../components/text/Hyperlink";

let hoverTimeout = null;

  const practiceTypes = [
    "Private Practice",
    "Hospital-Based Practice",
    "Academic/Teaching Institution",
    "Outpatient Clinic/Group Practice"
  ];
  
  const specialties = [
    "Family Medicine",
    "Internal Medicine",
    "Pediatrics",
    "Geriatrics",
    "Cardiology",
    "Endocrinology",
    "Gastroenterology",
    "Hematology",
    "Infectious Disease",
    "Nephrology",
    "Neurology",
    "Oncology",
    "Pulmonology",
    "Rheumatology",
    "General Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Cardiothoracic Surgery",
    "Vascular Surgery",
    "Plastic Surgery",
    "Otolaryngology (ENT) Surgery",
    "Urology",
    "Obstetrics",
    "Gynecology",
    "Reproductive Endocrinology and Infertility",
    "Maternal-Fetal Medicine",
    "Emergency Medicine",
    "Critical Care Medicine",
    "Trauma Surgery",
    "Psychiatry",
    "Psychology",
    "Addiction Medicine",
    "Dermatology",
    "Ophthalmology",
    "Allergy and Immunology",
    "Physical Medicine and Rehabilitation (PM&R)",
    "Pain Management",
    "Sleep Medicine",
    "Radiology",
    "Pathology",
    "Anesthesiology",
    "Occupational Medicine",
    "Interventional Cardiology",
    "Pediatric Cardiology",
    "Geriatric Psychiatry",
    "Pediatric Neurology",
    "Sports Medicine",
    "Telemedicine",
    "Integrative and Holistic Medicine",
    "Preventive Medicine",
    "Palliative Care",
    "Genetic Counseling",
    "Physiotherapy",
    "Behavioral Health",
    "Speech-Language Pathology",
    "Nutrition and Dietetics",
    "Chiropractic Medicine",
    "Audiology",
    "Pain Rehabilitation",
    "Other",
  ];
  

function SignUpView() {
  const { addAlert } = useContext(AlertContext);
  const { referralCode } = useParams();
  const navigate = useNavigate();
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [agreeValue, setAgreeValue] = useState(false);
  const [region, setRegion] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [practicetype, setPracticetype] = useState(null);
  const [organizationname, setOrganizationname] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [referral, setReferral] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [pageStep, setPageStep] = useState(1);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPopup, setPopup] = useState(false);
  const [confirmName, setConfirmName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [signatureError, setSignatureError] = useState("");

  const handleMouseEnter = () => {
    if (hoverTimeout) clearTimeout(hoverTimeout); // Clear any existing timeout
    setShowPasswordRequirements(true); // Show tooltip
  };
  
  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setShowPasswordRequirements(false); // Hide tooltip after delay
    }, 200); // Delay for smooth transition
  };

  const handlePhoneNumberChange = (event) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedNumber);
  };
  
  const handleOrganizationnameChange = (event, organizationname) => {
    setOrganizationname(organizationname);
  };
  
  const handleSpecialtyChange = (event, specialty) => {
    setSpecialty(specialty);
  };
  
  const handleRegionChange = (event, region) => {
    setRegion(region);
  };
  
  const handlePracticetypeChange = (event, practicetype) => {
    setPracticetype(practicetype);
  };
  
  const handleFirstNameChange = (event) => {
    setFirstNameValue(event.target.value);
  };
  
  const handleLastNameChange = (event) => {
    setLastNameValue(event.target.value);
  };
  
  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };
  
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };
  
  const handleCheckboxClick = (event) => {
    setAgreeValue(event.target.checked);
  };


  useEffect(() => {
    if (referralCode) {
      setReferral(referralCode);
    }
  }, [referralCode]);

  useEffect(() => {
    setErrorMessage(null); // Reset error when any input changes
  }, [
    firstNameValue,
    lastNameValue,
    passwordValue,
    emailValue,
    phoneNumber,
    region,
    specialty,
    practicetype,
    organizationname,
    agreeValue,
  ]);
  

  const handleNextPage = (event) => {
    event.preventDefault();
  
    let error = null;
  
    if (pageStep === 1) {
      // Step 1 validations
      if (!emailValue) {
        error = "Please enter an email";
      } else if (!emailValue.includes("@")) {
        error = "Please enter a valid email";
      } else if (!passwordValue) {
        error = "Please enter a password";
      } else if (passwordValue.length <= 8) {
        error = "Password must be at least 9 characters";
      } else if (!/[^a-zA-Z0-9_]/.test(passwordValue)) {
        error = "Password must contain one special character";
      } else if (passwordValue !== confirmPassword) {
        error = "Passwords do not match";
      }
    } else if (pageStep === 2) {
      // Step 2 validations
      if (!firstNameValue) {
        error = "Please enter your first name";
      } else if (!lastNameValue) {
        error = "Please enter your last name";
      } else if (!phoneNumber || phoneNumber.length <= 9) {
        error = "Please enter a valid phone number";
      } else if (!region) {
        error = "Please select a region";
      }
    }
  
    if (error) {
      setErrorMessage(error); // Set error and prevent navigation
      return;
    }
  
    // Clear errors and move to the next step
    setErrorMessage(null);
    setPageStep((prevStep) => prevStep + 1);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let error = null;
  
    // Step 3 validations
    if (!specialty) {
      error = "Please select your specialty";
    } else if (!practicetype) {
      error = "Please select your practice type";
    } else if (!organizationname) {
      error = "Please enter your organization name";
    } else if (!agreeValue) {
      error = "You must accept the Terms of Service and Privacy Policy";
    }
  
    if (error) {
      setErrorMessage(error); // Set error and prevent submission
      return;
    }
  
    setErrorMessage(null);
  
    const newUser = {
      full_name: `${firstNameValue} ${lastNameValue}`,
      email: emailValue.toLowerCase(),
      password: passwordValue,
      phone_number: phoneNumber,
      job_title: organizationname,
      emr_ehr_provider: region,
      practice: practicetype,
      specialty: specialty,
      referral_code: referral || null,
      force_update_password: false,
      };
  
    try {
      await axios.post(
        `${
          process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST
        }/api/user`,
        newUser,
        { headers: { "Content-Type": "application/json" } }
      );
  
      addAlert(
        "Success! You will be redirected to the login page in 5 seconds!",
        "success"
      );
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.detail || "An error occurred. Please try again."
      );
    }
  };
  
  
  

  const goBack = () => {
    if (pageStep > 1) {
      setPageStep((prevStep) => prevStep - 1); 
    } else {
      navigate("/");
    }
  };
  

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={(theme) => ({
          margin: "25px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          boxShadow: theme.shadow.md,
          backgroundColor: "rgb(255, 255, 255, 50%)",
          [theme.breakpoints.up("sm")]: {
            width: "30%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            alignSelf: "center",
          },
          alignItems: "center",
          paddingBottom: "30px",
          marginBottom: "30px",
        })}
      >
        <IconButton
          onClick={goBack}
          sx={{
            marginTop: "15px",
            marginLeft: "15px",
            height: "50px",
            width: "50px",
            alignSelf: "start",
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: "2rem",
          }}
        >
          <img src="/assets/logo.png" alt="Logo" style={{ maxWidth: "80%" }} />
        </Box>

        <Box id="signup-form" sx={{ gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "0.75rem",
            }}
          >
            {/* Horizontal Stepper */}
            <Stepper
              value={pageStep - 1}
              color="primary"
              sx={{
                "--Joy-Connector-thickness": "50px",
                "--Joy-Connector-lineColor": "#FFC0CB",
                "--Joy-StepIcon-size": "28px",
                mb: 2,
                [`& .${stepClasses.active}::after, & .${stepClasses.completed}::after`]: {
                  backgroundColor: "#FFC0CB",
                },
              }}
            >
              <Step>
                <StepIndicator>{pageStep > 1 ? <CheckIcon /> : "1"}</StepIndicator>
              </Step>
              <Step>
                <StepIndicator>{pageStep > 2 ? <CheckIcon /> : "2"}</StepIndicator>
              </Step>
              <Step>
                <StepIndicator>{pageStep > 3 ? <CheckIcon /> : "3"}</StepIndicator>
              </Step>
            </Stepper>

            {/* STEP 1 */}
            {pageStep === 1 && (
              <>
                <Typography level="h4" sx={{ fontWeight: 600, mb: 1 }}>
                  Create your account
                </Typography>
                <Typography level="body2" sx={{ color: "text.secondary", mb: 2 }}>
                  Get started with OneChart.ai
                </Typography>

                <FloatingLabelInput
                  label="Email address"
                  value={emailValue}
                  onChange={handleEmailChange}
                  
                />
                <Box
                  sx={{ position: "relative", display: "inline-block" }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <FloatingLabelInput
                    label="Password"
                    value={passwordValue}
                    onChange={handlePasswordChange}
                    
                    type={viewPassword ? "text" : "password"}
                    icon={
                      <IconButton onClick={() => setViewPassword(!viewPassword)}>
                        {viewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    }
                  />

                  {/* Tooltip for Password Requirements */}
                  {showPasswordRequirements && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        mt: 1,
                        backgroundColor: "#ffffff", 
                        border: "1px solid #ddd", 
                        borderRadius: "8px", 
                        padding: "16px", 
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
                        maxWidth: "300px", 
                        textAlign: "left", 
                        opacity: showPasswordRequirements ? 1 : 0, 
                        transform: showPasswordRequirements
                          ? "translateY(0)"
                          : "translateY(-10px)", 
                        transition: "opacity 0.2s ease, transform 0.2s ease",
                        zIndex: 10, 
                        pointerEvents: "none", 
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.875rem",
                          marginBottom: "8px",
                          color: "#333333",
                        }}
                      >
                        Password Requirements:
                      </Typography>
                      <ul style={{ paddingLeft: "20px", margin: 0, fontSize: "0.875rem" }}>
                        <li>At least 9 characters</li>
                        <li>Include at least one special character</li>
                        <li>No spaces</li>
                      </ul>
                    </Box>
                  )}
                </Box>


                <FloatingLabelInput
                  label="Confirm Password"
                  
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  type={viewConfirmPassword ? "text" : "password"}
                  icon={
                    <IconButton onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                      {viewConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  }
                />


                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <Button
                onClick={handleNextPage} // Navigate to the next step
                sx={{
                    backgroundColor: "var(--dark-blue-button)", // Use dark blue button color
                    color: "var(--light-gray)", // Use light gray for the text
                    width: "110px", // Maintain consistent button width
                    height: "40px", // Maintain consistent height
                    display: "flex", // Enable flexbox for centering
                    justifyContent: "center", // Center text horizontally
                    alignItems: "center", // Center text vertically
                    fontWeight: "bold", // Bold text for emphasis
                    fontSize: "0.875rem", // Consistent font size
                    borderRadius: "8px", // Rounded button corners
                    "&:hover": {
                    backgroundColor: "var(--dark-blue-button-hover)", // Use hover color
                    },
                }}
                >
                Continue
                </Button>
                </Box>


                {errorMessage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
                </Box>
              )}
              </>
            )}

            {/* STEP 2 */}
            {pageStep === 2 && (
              <>
                <Typography level="h4" sx={{ fontWeight: 600, mb: 1 }}>
                  Personal Information
                </Typography>
                <Typography level="body2" sx={{ color: "text.secondary", mb: 2 }}>
                  Tell us about yourself
                </Typography>

                <Box sx={{ display: "flex", gap: 2 }}>
                  <FloatingLabelInput
                    label="First name"
                    value={firstNameValue}
                    onChange={handleFirstNameChange}
                   
                  />
                  <FloatingLabelInput
                    label="Last name"
                    value={lastNameValue}
                    onChange={handleLastNameChange}
                   
                  />
                </Box>

                <FloatingLabelInput
                label="Phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
               
              />

                <Select
                  placeholder="Select Region"
                  value={region}
                  onChange={handleRegionChange}
                  sx={{
                    fontFamily: "Inter, sans-serif", // Apply to the Select text
                  }}
                 
                >
                  <Option value="canada">Canada</Option>
                  <Option value="usa">United States of America</Option>
                  <Option value="other">Other</Option>
                </Select>

                <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between", // Place buttons at opposite ends
                    alignItems: "center", // Align buttons vertically
                    mt: 3, // Add spacing above the buttons
                }}
                >
                <Button
                    onClick={() => setPageStep(1)} // Navigate back to Step 1
                    sx={{
                        backgroundColor: "var(--light-gray)", // Use light blue button color
                        color: "var(--dark-blue-button)", // Use dark blue text color
                        width: "110px", // Consistent button width
                        height: "40px", // Consistent height for better alignment
                        display: "flex", // Enable flexbox for centering
                        justifyContent: "center", // Center text horizontally
                        alignItems: "center", // Center text vertically
                        fontWeight: "bold", // Bold text
                        fontSize: "0.875rem", // Consistent font size
                        borderRadius: "8px", // Rounded corners for the button
                        "&:hover": {
                          backgroundColor: "var(--light-blue-button-hover)", // Use hover color
                        },
                      }}
                >
                    Back
                </Button>
                <Button
                onClick={handleNextPage} // Navigate to the next step
                sx={{
                    backgroundColor: "var(--dark-blue-button)", // Use dark blue button color
                    color: "var(--light-gray)", // Use light gray for the text
                    width: "110px", // Maintain consistent button width
                    height: "40px", // Maintain consistent height
                    display: "flex", // Enable flexbox for centering
                    justifyContent: "center", // Center text horizontally
                    alignItems: "center", // Center text vertically
                    fontWeight: "bold", // Bold text for emphasis
                    fontSize: "0.875rem", // Consistent font size
                    borderRadius: "8px", // Rounded button corners
                    "&:hover": {
                    backgroundColor: "var(--dark-blue-button-hover)", // Use hover color
                    },
                }}
                >
                Continue
                </Button>
                </Box>



                {errorMessage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
                </Box>
              )}
              </>
            )}

            {/* STEP 3 */}
            {pageStep === 3 && (
              <>
                <Typography level="h4" sx={{ fontWeight: 600, mb: 1 }}>
                  Practice Information
                </Typography>
                <Typography level="body2" sx={{ color: "text.secondary", mb: 2 }}>
                  Tell us about your practice
                </Typography>

    
                <Autocomplete
                  placeholder="Select Specialty"
                  forcePopupIcon={false}
                  
                  options={specialties} 
                  value={specialty} 
                  disableClearable
                  onChange={handleSpecialtyChange} 
                  sx={{
                    fontFamily: "Inter, sans-serif", // Apply to the Select text
                  }}
                />



                  <Autocomplete
                  placeholder="Select Practice Type"
                  forcePopupIcon={false}
                  options={practiceTypes} 
                  disableClearable
                  value={practicetype} 
                  sx={{
                    fontFamily: "Inter, sans-serif", // Apply to the Select text
                  }}
                  onChange={handlePracticetypeChange} 
                  />

                <Autocomplete
                  freeSolo
                  placeholder="Organization Name"
                  forcePopupIcon={false} 
                  options={[]}
                  disableClearable
                  value={organizationname}
                  sx={{
                    fontFamily: "Inter, sans-serif", // Apply to the Select text
                  }}
                  onInputChange={(event, newInputValue) => setOrganizationname(newInputValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Organization Name"
                    />
                  )}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
                <Checkbox
                    checked={agreeValue}
                    onChange={handleCheckboxClick} // Pass the full event
                    sx={{ padding: "0" }} // Optional: Adjust padding
                />
                <Typography>
                    I acknowledge the{" "}
                    <HyperLink text="terms of service" link="https://onechart.ai/privacy/" /> and{" "}
                    <HyperLink text="privacy policy" link="https://onechart.ai/privacy/" />.
                </Typography>
                </Box>


                <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between", // Place buttons at opposite ends
                    alignItems: "center", 
                    mt: 3, 
                    gap: 2, 
                }}
                >
                <Button
                    onClick={() => setPageStep(2)} 
                    sx={{
                      backgroundColor: "var(--light-gray)", // Use light blue button color
                      color: "var(--dark-blue-button)", // Use dark blue text color
                      width: "110px", // Consistent button width
                      height: "40px", // Consistent height for better alignment
                      display: "flex", // Enable flexbox for centering
                      justifyContent: "center", // Center text horizontally
                      alignItems: "center", // Center text vertically
                      fontWeight: "bold", // Bold text
                      fontSize: "0.875rem", // Consistent font size
                      borderRadius: "8px", // Rounded corners for the button
                      "&:hover": {
                        backgroundColor: "var(--light-blue-button-hover)", // Use hover color
                      },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={handleSubmit} 
                    sx={{
                    backgroundColor: "var(--dark-blue-button)", 
                    color: "var(--light-gray)", 
                    width: "150px", 
                    height: "40px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    fontWeight: "bold", 
                    fontSize: "0.875rem", 
                    borderRadius: "8px", 
                    "&:hover": {
                        backgroundColor: "var(--dark-blue-button-hover)", 
                    },
                    }}
                >
                    Complete Setup
                </Button>
                </Box>

                {errorMessage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
                </Box>
              )}
              </>
            )}
          </Box>

          {/* Footer Link */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            
            <Typography
              level="body2"
              sx={{
                fontSize: "0.875rem",
                color: "text.secondary",
              }}
            >
              Already have an account?{" "}
              <Typography
                component="a"
                href="/login"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Sign in
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SignUpView;
