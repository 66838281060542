import React, { useEffect, useState } from "react";
import "../styling/LoginView.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { broadcastEvent, authGuard } from "../services/Authentication";
import { Box, IconButton, FormControl, FormLabel, Button, Typography, Link, Divider, Card } from "@mui/joy";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Lock';
import FloatingLabelInput from "../components/joy/FloatingInput";

function LoginView() {
    const navigate = useNavigate();
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            if (await authGuard()) {
                navigate('/new-visit');
            }
        }
        checkUser();
    }, []);

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    }

    const setViewPasswordChange = (value) => {
        setViewPassword(value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const loginRequest = {
            "email": emailValue.toLowerCase(),
            "password": passwordValue,
        }
        await axios.post(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/login`, loginRequest,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user_id);
            if (response.data.force_update_password) {
                navigate('/reset-password');
            } else {
                broadcastEvent('localStorageChange', { key: 'token', value: response.data.token });
                navigate('/new-visit');
            }
        }).catch(error => {
            setErrorMessage("Incorrect email or password.")
        });
    };

    const goBack = () => {
        navigate('/');
    }

    const goForgotPassword = () => {
        navigate('/forgot-password');
    }

    const goSignUp = () => {
        navigate('/signup');
    }

    return (
        <Box sx={{ display: "flex", flexGrow: 1, height: "100%", justifyContent: "center",paddingBottom: "20px",}}>
            <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: {xs: '45%', md: "30%"}
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    alignSelf: 'center',
                }
            })}>
                <IconButton onClick={goBack} sx={{
                        marginTop: '15px',
                        marginLeft: '15px',
                        height: '50px',
                        width: '50px',
                    }}>
                        <ArrowBackIcon />
                </IconButton>
                <Box
                    sx={{display: "flex", justifyContent: "center",  alignItems: "center", width: "100%", mt:"2rem"}}
                >
                    <img src="/assets/logo.png" alt='Logo' style={{maxWidth: "80%"}}/>
                </Box>
                <form onSubmit={handleSubmit} id="login-form">
                    <FormControl sx={{ alignSelf: 'center', padding: '20px', width: '100%', gap: 2}}>
                        <FormLabel sx={{ fontSize: '1.5rem', fontWeight:"600"}}>Welcome Back!</FormLabel>
                        <Typography> Please enter your sign in details below:</Typography>
                        <FloatingLabelInput label="Email" value={emailValue} onChange={handleEmailChange} type="text" name="email"/>
                        <FloatingLabelInput label="Password" value={passwordValue} onChange={handlePasswordChange} type={viewPassword ? "text" : "password"} name="password" icon={<IconButton onClick={() => {setViewPasswordChange(!viewPassword)}}>
                            <VisibilityIcon/>
                        </IconButton>}/>
                        {errorMessage && <Typography name="error" sx={{ color: "red"}}> {errorMessage} </Typography>}
                        <Link onClick={goForgotPassword} sx={{alignSelf: 'end'}}>Forget Password?</Link>
                        <Button type="submit" sx={{ fontSize: "1rem", padding: '10px'}}> Sign In </Button>
                        <Divider> <Typography level="body-sm">Don't have an account?&nbsp;</Typography><Link onClick={goSignUp} fontSize="sm">Sign Up</Link></Divider>
                    </FormControl>
                </form>
                <Card
                variant="outlined"
                sx={{
                    mt: -10, 
                    mx: "auto", 
                    padding: "12px",
                    borderRadius: "12px",
                    backgroundColor: "rgba(241, 243, 244, 0.1)",
                    position: "relative", 
                    border: "none",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <LockIcon sx={{ fontSize: "24px", color: "var(--dark-blue-button-hover)" }} />
                    <Typography level="h6" sx={{ fontWeight: "bold", textAlign: "left",color: "var(--dark-blue-button-hover)",fontSize: "0.750rem", fontFamily: "Inter",}}>
                        HIPAA Compliant
                    </Typography>
                </Box>
                <Typography
                    level="body2"
                    sx={{
                        mt: 0,
                        color: "var(--dark-blue-button-hover)",
                        textAlign: "left",
                        fontSize: "0.700rem",
                        fontFamily: "Inter",
                    }}
                >
                    Your privacy is our priority. All data is encrypted and securely stored following leading industry standards.
                </Typography>
            </Card>
            </Box>
        </Box>
  );
}

export default LoginView